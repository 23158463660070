



































import { Component, Vue, Prop } from "vue-property-decorator";
import Zhengzhuangqunfenxi from "./reports/zhengzhuangqunfenxi.vue";
import Zhengzhuangteyixingfenxi from "./reports/zhengzhuangteyixingfenxi.vue";
import Yongyaopeiwufenxi from "./reports/yongyaopeiwufenxi.vue";
import Yongyaoteyixingfenxi from "./reports/yongyaoteyixingfenxi.vue";
import Suizhengyongyaoguilv from "./reports/suizhengyongyaoguilv.vue";

@Component({
  components: {
    Zhengzhuangqunfenxi,
    Zhengzhuangteyixingfenxi,
    Yongyaopeiwufenxi,
    Yongyaoteyixingfenxi,
    Suizhengyongyaoguilv,
  },
})
export default class ReportTransfer extends Vue {
  @Prop()
  private result: any;
  private resultChange() {
    this.$emit("update:result", this.result);
  }
  /**
   * @description 关联规则导出数据
   */
  private guanlianguizeExport(name: any) {
    this.$emit("download", "关联规则", name);
  }
  private mounted() {
    // console.log(this.result);
  }
}
